
import { useAuth0 } from "@auth0/auth0-react";
import API from "@sesame/web-api";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { ClientLogo } from "src/components";
import ModalDialog from "src/components/ModalDialog";
import { Spinner } from "src/components/Spinner";
import Alert, { AlertTypes } from "src/components/AlertDisplay"
import { ILoadState, INewTestEvent, ITransceiver, ValidEventNames } from "src/types";
import { Navigate } from "react-router-dom";
import { getTransceiver, sendScannedEvent, workflowStates } from "./common";
import { beep } from "src/util/PlaySound";


export const FWPackout = () => {

  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW)
  const [packageCode, setPackageCode] = useState("");
  const [validPackage, setValidPackage] = useState(false);
  const [serial, setSerial] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [scannedTx, setScannedTx] = useState<string[]>([])

  const configVals = {
    title: 'Scan Transceivers for Packout',
    testName: workflowStates[4] as ValidEventNames,
  }


  const boxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pkg = e.currentTarget.value;
    setPackageCode(pkg);
    setValidPackage(
      pkg.match(/^27\d+$/) != null
    )
    setSerial("");
    setScannedTx([]);
  }

  const txScanned = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSerial(e.currentTarget.value);
  }

  const addScan = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (!validPackage) {
        beep(90, 'sine', 510, setTimeout(() => beep(100, 'sine', 340, undefined) , 130));
    }
    const parts = serial.split(" ");
    const serno = parts[0];
    /*
    var p = partNo;
    if (parts.length > 1) {
      p = parts[1];
    }
    */



    const getTx = async () => {
      const accessToken = await getAccessTokenSilently();
      //const txs = await getTransceiver(accessToken, workflowStates[2], serno)
      const txs = await getTransceiver(accessToken, workflowStates[0], serno)

      document.getElementById('serialInput')?.focus();
      if (!serno.length) {
        return;
      }

      /* Disabled 2024-10-9 for part number update w/o verify
      if (txs.length == 0) {
        setErrMsg("Transceiver Upgrade not recorded: " + serno)
        //TODO: get the PN and record the failure.  Can check if not upgraded or not tested.
        beep(90, 'sine', 510, setTimeout(() => beep(100, 'sine', 340, undefined), 130));
        setSerial("")
        return;
      }
      if (txs[0].result != 'P') {
        setErrMsg("Transceiver Firmware Not Upgraded: " + serno)
        beep(90, 'sine', 510, setTimeout(() => beep(100, 'sine', 340, undefined), 130));
        //TODO: get the PN and record the failure.  Can check if not upgraded or not tested.
        setSerial("")
        return;
      }
      */

      const d = new Date()
      const newTe: INewTestEvent = {
        date: d.toJSON(),
        location: packageCode,
        name: configVals.testName,
        result: "P", // txs.length == 0 ? "F" : "P",
        serialNo: serno,
        partNo: txs[0].partNo,
        revision: txs[0].revision,
        vendor: txs[0].vendor,
        output: "",
      }
      let ok = await sendScannedEvent(accessToken, newTe)
      .catch( err => {
        ok = false;
      });

      if (!ok && !errMsg) {
        setErrMsg("Error updating database");
        return;
      }
      const ix = scannedTx.findIndex(s => s == serno);
      if (ix >= 0) {
        scannedTx.splice(ix, 1)
      }
      beep(50, 'sine', 610);
      setScannedTx([serno, ...scannedTx]);
      setSerial("");
      setErrMsg("")

    }
    getTx();

  }
  if (!isAuthenticated) {
    return <Navigate to="/" replace />
  }

  return <div className="txfwpage">
    <h2>{configVals.title}</h2>
    <form>
      <label className="uk-label highlight-packout">Package Code</label>
      <br />
      <input className="uk-input uk-width-1-2"
        type="text"
        placeholder="Scan package/bag code"
        value={packageCode}
        autoFocus={packageCode == ""}
        onChange={e => boxChanged(e)}
      />
      <br />
      <br />
      <label className="uk-label highlight-packout">Transceiver Serial Number</label>
      <br />
      <input className="uk-input uk-width-1-2"
        id="serialInput"
        type="text"
        placeholder="Scan transceiver barcode"
        value={serial}
        autoFocus={packageCode != ""}
        disabled={!validPackage}
        onChange={e => txScanned(e)}
      />
      <br />
      <div className="uk-text-danger">{errMsg}</div>
      <br />
      <button className="uk-button uk-button-primary highlight-packout"
        onClick={e => addScan(e)}>Record</button>
    </form>
    <h2>Box Count: {scannedTx.length}</h2>
    <ul className="uk-list">
      {scannedTx.map(t => <li>{t}</li>)}
    </ul>
  </div>
}
