
import { useAuth0 } from "@auth0/auth0-react";
import API from "@sesame/web-api";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Spinner } from "src/components/Spinner";
import Alert, { AlertTypes } from "src/components/AlertDisplay"
import { ILoadState, ITestEvent, UpgradeEvents, ValidEventNames } from "src/types";
import { Navigate } from "react-router-dom";
import dayjs from "dayjs";

interface IRepDataPoint {
  name: ValidEventNames;
  test_location: string;
  test_date: Date;
  package_count: number;
  serial_count: number;
}
interface ITotal {
  package: number;
  serial: number;
}

const PAGETITLE = 'Carton Summary';

export const CartonSummary = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState<IRepDataPoint[]>([]);
  const [total, setTotal] = useState<ITotal>({package: 0, serial: 0})
  const [displayState, setDisplayState] = useState<ILoadState>(ILoadState.NEW)

  const processData = (result: IRepDataPoint[]): IRepDataPoint[] => {
    const output: IRepDataPoint[] = [];
    // Sort in order, then add pass/fail total for each unique item

    const sorted = result.filter(e => e.name in UpgradeEvents).sort((a, b) => {
      if (a.test_date < b.test_date) return -1;
      if (a.test_date > b.test_date) return 1;
      if (a.test_location < b.test_location) return -1;
      if (a.test_location > b.test_location) return 1;
      return 0
    })
    const totals: ITotal = {
      package: 0,
      serial: 0,
    }
    sorted.map(s => { totals.package += s.package_count; totals.serial += s.serial_count})
    setTotal(totals);
    return sorted;

  }

  useEffect(() => {
    getAccessTokenSilently().then(async accessToken => {
      let ok = true;
      const res = await API.get(accessToken, '/api/v1/report/testEvents/cartonSummary', {timeout: 30000})
        .catch(err => {
          Alert(AlertTypes.ERROR, err.message);
          setDisplayState(ILoadState.ERROR);
          ok = false;
          return;
        })
      if (!ok) { 
        return;
      }
      const sorted = processData(res.data)
      setData(sorted)
      setDisplayState(ILoadState.READY);
    })
    setDisplayState(ILoadState.LOADING);
  }, [])

  if (!isAuthenticated) {
    return <Navigate to="/" replace />
  }
  if (isLoading || displayState == ILoadState.LOADING || displayState == ILoadState.NEW) {
    return <div><h2>Carton Summary</h2>
      <div><Spinner /></div>
    </div>
  }

  if (displayState == ILoadState.ERROR) {
    return <div><h2>{PAGETITLE}</h2>
      <p className="uk-text-danger">Error loading data</p>
    </div>
  }

  return <div>
    <h2>{PAGETITLE}</h2>
    <table className="uk-table uk-table-small">
      <tr>
        <th>
          Date
        </th>
        <th>
          Carton
        </th>
        <th>
          Package Count
        </th>
        <th>
          Transceiver Count
        </th>
      </tr>
      <tr className="totalRow">
        <td colSpan={2}>
          Total
        </td>
        <td>
          {total.package}
        </td>
        <td>
          {total.serial}
        </td>
      </tr>

      {data.map((d, i) => <tr key={i}>
        <td>
          {dayjs(d.test_date).format('YYYY-MM-DD')}
        </td>
        <td>
          {d.test_location}
        </td>
        <td>
          {d.package_count}
        </td>
        <td>
          {d.serial_count}
        </td>
      </tr>
      )}
    </table>
  </div>
}
