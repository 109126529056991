import React, { Fragment } from 'react';
//import { useAuth0 } from '@auth0/auth0-react';
import { NavLink } from 'react-router-dom';
import { hideDropDown } from 'src/util/UIUtil';


export const PrimaryNav = () => {
  //  const { isAuthenticated } = useAuth0();
  //  if (! isAuthenticated) {
  //    return <Fragment />
  //  }

  return (
    <Fragment>
      <nav className="uk-navbar-container uk-dropnav uk-flex-column uk-flex-top uk-padding-none"
        data-uk-dropnav data-uk-navbar data-uk-toggle="media: @s; cls: uk-flex-row uk-flex-top; mode: media">
        <button data-uk-toggle="target: .navbar-collapse; cls: uk-visible@s uk-padding-remove"
          className="uk-navbar-toggle uk-padding uk-padding-remove-vertical uk-hidden@s" data-uk-navbar-toggle-icon></button>
        <div className="navbar-collapse uk-visible@s">
          <div className="uk-navbar-left" >
            &nbsp;
            <ul data-uk-toggle="media: @s; cls: uk-navbar-nav uk-padding-remove; mode: media"
              className="uk-nav uk-navbar-nav uk-padding-remove uk-text-uppercase">
              <li>
                <NavLink to="/hosts" className={({ isActive }) => isActive ? "uk-text-primary uk-active" : undefined}>Hosts</NavLink>
              </li>
              <li>
                <NavLink to="/transceivers" className={({ isActive }) => isActive ? "uk-text-primary uk-active" : undefined}>Transceivers</NavLink>
              </li>
              <li>
                <a href="#">Firmware Upgrade <span data-uk-drop-parent-icon></span></a>
                <div className="uk-dropdown">
                  <ul className="uk-nav uk-dropdown-nav fwnav-list">
                    <li>
                      <NavLink to="/fw/checkin" className={({ isActive }) => isActive ? "uk-text-primary uk-active" : undefined}>Receiving</NavLink>
                    </li>
                    {/* <li>
                      <NavLink to="/fw/swstatus" className={({ isActive }) => isActive ? "uk-text-primary uk-active" : undefined}>Switch Status</NavLink>
                    </li>
                    */}
                    <li>
                      <NavLink to="/fw/qascan" className={({ isActive }) => isActive ? "uk-text-primary uk-active" : undefined}>Verify</NavLink>
                    </li>
                    <li>
                      <NavLink to="/fw/checkout" className={({ isActive }) => isActive ? "uk-text-primary uk-active" : undefined}>Packout</NavLink>
                    </li>
                    <li>
                      <NavLink to="/fw/cartonpack" className={({ isActive }) => isActive ? "uk-text-primary uk-active" : undefined}>Carton Pack</NavLink>
                    </li>
                    <li>
                      <NavLink to="/fw/failed" className={({ isActive }) => isActive ? "uk-text-primary uk-active" : undefined}>Scan Errors</NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a href="#">Reporting <span data-uk-drop-parent-icon></span></a>
                <div className="uk-dropdown">
                  <ul className="uk-nav uk-dropdown-nav">
                    <li>
                      <NavLink to="/reports/fwtxhistory" className={({ isActive }) => isActive ? "uk-text-primary uk-active" : undefined}>Transceiver History</NavLink>
                      <NavLink to="/reports/fwsummary" className={({ isActive }) => isActive ? "uk-text-primary uk-active" : undefined}>Upgrade Summary</NavLink>
                      <NavLink to="/reports/cartonsummary" className={({ isActive }) => isActive ? "uk-text-primary uk-active" : undefined}>Carton Summary</NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </Fragment >
  );
};

